<template>
  <Head>
    <title>{{ogtitle}}</title>
    <meta name="description" :content="dynamicDescription">
<!--    Микроразметка-->
    <meta property="og:locale" :content="oglocale"/>
    <meta property="og:type" content="article"/>
    <meta property="og:title" :content="ogtitle"/>
    <meta property="og:description" :content="dynamicDescription"/>
    <meta property="og:image" content="https://internationaltrashaward.org/international-trash-award.png"/>
    <meta property="og:image:width" content="630"/><meta property="og:image:height" content="630"/>
    <meta property="og:url" :content="ogurl"/>
    <meta property="og:site_name" content="internationaltrashaward.org"/>
  </Head>
  <router-view/>
</template>
<script lang="ts">
import { useI18n } from 'vue-i18n'
import {mapState} from "vuex";
import {useHead} from "@unhead/vue";
import {onBeforeMount, onMounted, ref, watch } from 'vue';
import { Head } from '@unhead/vue/components'
import router from "@/router";
import { useRoute } from 'vue-router';


export default {
  components: { Head },


  setup() {
    const { t, locale } = useI18n({useScope: 'global'})


    const route = useRoute();

    const currentLanguage = route.params.locale as string | null;
    locale.value = localStorage.getItem('saveLocal') || currentLanguage || 'en'; //

    const currentRoute = router.currentRoute.value.fullPath;

    const dynamicDescription = ref('gfg');
    const oglocale = ref(t('Ru_ru'));
    const ogtitle = ref(t('Международная мусорная премия'));


    const ogurl = ref('jhnhij');

    onBeforeMount(() => {
      const updateOgUrl = () => {
        ogurl.value = 'https://internationaltrashaward.org' + router.currentRoute.value.fullPath;

        const currentLanguage2 = route.params.locale as string;
        let description = '';
        let title = '';

        if (currentLanguage2 === 'ru') {
          description = 'Премия за выдающийся вклад в убийство планеты';
          title = 'Международная мусорная премия';
        } else if (currentLanguage2 === 'es') {
          description = 'Un premio por una contribución sobresaliente a la destrucción del planeta';
          title = 'Premio Internacional de Basura';
        } else if (currentLanguage2 === 'zh') {
          description = '为对杀死地球做出杰出贡献而颁发的奖项';
          title = '国际垃圾奖';
        } else if (currentLanguage2 === 'ar') {
          description = 'جائزة لمساهمة متميزة في قتل الكوكب';
          title = 'جائزة النفايات الدولية';
        } else if (currentLanguage2 === 'fr') {
          description = 'Un prix pour une contribution exceptionnelle à la destruction de la planète';
          title = 'Prix international des déchets';
        } else {
          description = 'An award for an outstanding contribution to the killing of the planet';
          title = 'International Trash Award';
        }

        dynamicDescription.value = description;
        ogtitle.value = title
      };

      watch(() => router.currentRoute.value.fullPath, () => {
        updateOgUrl();
      });

      updateOgUrl(); // Обновляем ogurl при монтировании компонента
    });

    // Подписка на изменения маршрута для обновления ogurl
    router.currentRoute.value.fullPath;


    return {
      t, locale, dynamicDescription,
      oglocale,
      ogtitle,
      ogurl,
      currentRoute
    }
  },
  created() {
    const { t } = useI18n(); // Получаем функцию перевода
    const setTitle = t('Международная мусорная премия')
    useHead ({title: setTitle})

  },

  computed: {
    ...mapState({
      saveLocal: "saveLocal"
    })
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #ffffff;
  margin: 15px 0 45px 0;
}

body {
  margin: 0;
  padding: 0;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #2c3e50;
}

a {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

a.router-link-exact-active {
  color: #2c3e50;
}

a:visited {
}

@media (max-width: 800px) {
  #app {
    margin: 0 0 0 0;
  }
}
</style>
