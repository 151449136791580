import en from './eng.json'
import ru from './rus.json'
import fr from './fra.json'
import es from './spa.json'
import ar from './ara.json'
import zh from './chn.json'

export const defaultLocale ='en'
export const languages = {
    en,
    ru,
    fr,
    es,
    ar,
    zh
}